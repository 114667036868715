.about-sec {
    text-align: left;
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 22%;
}

.about-sec h1 {
    color: #c889e6a2;
    padding-bottom: 4%;
}

.about-sec p {
    font-size: 1.5em;
}

.purps {
    color: #c889e6a2;
}