.btn {
    border: none;
    outline: none;
    border-radius: 4px;
    background: grey;
    background: linear-gradient(to right, #808080, #808080, #808080);
    color: #fff;
    transition: all 0.3s ease;
}

.btn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: #060809;
    color: grey;
    transition: 250ms;
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #060809;
}

.btn-large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20px;
    color: #060809;
}
