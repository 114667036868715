    .sticky {
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
  }

  .NavbarItems {
    background: #060809;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-right: 50px;
}

.navbar-logo {
    color: grey;
    justify-self: start;
    /* margin-left: 10px; */
    margin: 0 auto;
    cursor: pointer;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: grey;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: grey;
    color: #060809;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: grey;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

Button {
    transform: translate(0, -30%);
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 60vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }

    .nav-menu.active {
        background: #060809;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        color: grey;
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        opacity: 1;
    }

    .nav-links:hover {
        background-color: grey;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 20;
        left: 0;
        /* transform: translate(25%, 50%); */
        transform: translate(100%, 32%);
        color: grey;
        /* justify-self: start; */
        /* margin-left: 30px; */
        /* margin: 0 auto; */
        cursor: pointer;
        font-size: 1.8rem;
        margin-bottom: 20px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: grey;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #060809;
        text-decoration: none;
        color: grey;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: grey;
        color: #060809;
        transition: 250ms;
    }

    Button {
        z-index: -100;
    }

    a {
      z-index: -100;
    }
}