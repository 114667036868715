.contact-sect {
    height: 85vh;
}

h1 {
    cursor: pointer;
}

.email {
  cursor: pointer;
  color: rgba(200, 137, 230, 0.637);
}

.icon-section {
  margin-top: 5%;
}

.email:hover {
  color: gray;
}

.contact-icons {
    font-size: 4.5em;
    margin: 15px;
    padding: 10px;
    opacity: 0.93;
    border: 1.7px solid rgba(200, 137, 230, 0.637);
    vertical-align: middle;
    text-align: center;
    border-radius: 5px;
    display: inline-flex;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
    overflow: hidden;
    transition: all 0.4s ease 0s;
    color: gray;
  }

  .contact-container {
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    justify-content: center;
    height: 85vh;

}

@media (max-width: 767px) {
    .contact-icons {
      margin: 10px;
    }
  }
  
  .contact-icons:hover {
    transform: scale(1.05);
    overflow: hidden;
    border: 2.2px solid rgba(197, 115, 230, 0.883);
    color: gray;
  }
  
  .contact-icons-images {
    padding: 20px;
    line-height: 1.6;
  }

  @media (max-width: 417px) {
    .contact-icons {
      display: flex;
      
    }
  }