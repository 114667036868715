 .super {
    display: flex;
  
}

.resume {

    margin: auto;
    width: 50%;
    height: 700px;
    
    background-image: url("./jaredVeltsosDevResume.jpg");
    background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        /* margin-bottom: 5%;  */
    
     


}

@media (max-width: 1000px) {
    .main {
        display: flex;
        flex-direction: column;
    }

    .resume {
        height: 500px;
       
    }

    .resume-2 {
        height: 500px;
       
    }
}