.project-container {
  margin-top: 13%;
}

.img-container {
  margin-top: 3.75%;
  margin-bottom: 8%;
  padding-left: 8%;
}

.img {
  width: max-content;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  
}

.img a {
  text-decoration: none;
}


.img h2 {
  margin-bottom: 0;
  font-size: 4em;
  color: rgba(200, 137, 230, 0.637);  
}

.img:hover {
  padding-left: 5%;
}

.img:hover #logos {
  display: inline-block;
}

.img p {
  color: gray;
}

.gitLogo {
  text-decoration: none;
  cursor: pointer;
  color: gray;
  font-size: 2em;
}

.gitLogo:hover {
  color: rgba(200, 137, 230, 0.637);
}

.gittxt {
  font-size: .5em;
}
 

