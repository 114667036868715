.main-container {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 200px;  
}

.inner-container {
  font-size: 50px;
}

.wave {
    animation-name: wave-animation; /* Refers to the name of @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }  

  @media screen and (min-height: 935px) {
    .main-container {
      margin-top: 15%;
      padding-bottom: 15%;
    }
  }