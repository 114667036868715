  .skills-container {
      padding-left: 100px;
      padding-right: 100px;
      align-items: center;
      justify-content: center;
  }

  .title {
      text-align: center;
  }

  .title .bold {
      font-weight: 900;
      color: rgba(200, 137, 230, 0.637);
  }
  
  .tech-icons {
    font-size: 4.5em;
    margin: 15px;
    padding: 10px;
    opacity: 0.93;
    border: 1.7px solid rgba(200, 137, 230, 0.637);
    vertical-align: middle;
    text-align: center;
    border-radius: 5px;
    display: table;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
    overflow: hidden;
    transition: all 0.4s ease 0s;
  }
  
  @media (max-width: 767px) {
    .tech-icons {
      margin: 10px;
    }
  }
  
  .tech-icons:hover {
    transform: scale(1.05);
    overflow: hidden;
    border: 2.2px solid rgba(197, 115, 230, 0.883);
  }
  .tech-icons-images {
    padding: 20px;
    line-height: 1.6;
  }

  @media (max-width: 417px) {
    .tech-icons {
      display: flex;
      
    }
  }
  