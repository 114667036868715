.down-arrow i {
    color: rgba(200, 137, 230, 0.637);
  }
  
  .down-arrow i:hover {
    color: rgba(84, 64, 94, 0.637);
  }
  
  .down-arrow {
    font-size: 5rem;
    padding-top: 80px;
    animation-name: bounce; /* Refers to the name of @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    background: transparent;
    text-align: center;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
  }