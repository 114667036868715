
.footer {
    background-color: rgb(10, 4, 22);
  }
  
  .footer-copywright {
    text-align: center;
  }
  
  .footer-body {
    text-align: center;
  }

  .social-icons {
      list-style-type: none;
      display: inline-flex;
      padding-left: 1em;
      padding-right: 1em;
  }

  .social-icons a {
    color: gray;
  }

  .social-icons a:hover {
    color: rgba(200, 137, 230, 0.637);
  }
  
  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center;
    }
  
    .footer-body {
      text-align: center;
    }
  }
  
  .footer h3 {
    font-size: 1em;
    color: grey;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .footer-icons {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding: 0;
    cursor: pointer;
  }
