.intro-container {
    padding-left: 250px;
    padding-top: 200px;
    padding-bottom: 0px;
    font-size: 1.5rem;
  

}

.myself {
   color: rgba(200, 137, 230, 0.637);
}

@media (max-width: 900px) {
    .intro-container {
        padding-left: 10%;
        
    }
}